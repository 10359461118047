import React from 'react'
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import App from './App';
import Login from './component/Login';

export default function Home() {

  return (
    <>
     <BrowserRouter>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="app" element={<App/>}/>
        </Routes>
    </BrowserRouter>
    </>
  )
}
