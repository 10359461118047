import React from 'react';
import {useState, useContext, createContext} from 'react';

const userContext = React.createContext({
    authenticated: false,
    setAuthenticated: (auth) => {}
  })

  export default userContext;


