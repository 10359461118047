import React from 'react'

export default function PuntodeContact() {


    const handleClick = () => {



      window.open('https://api.whatsapp.com/send?phone=573208500871&text=', '_blank').focus();

    }
    
    
  return (
    <div>

<p>En caso de presentar preguntas o inquietudes, por favor contactar a las siguientes personas</p>
<p>Por favor hacer clic en el número de teléfono para abrir un chat en whatsapp</p>
<p>Adriana Arrieta</p><p>
Marketing Value Lead</p>
<p>Cel:  <a href="https://api.whatsapp.com/send?phone=573103279715&text=">+57 310 327 9715</a></p>

<p>Astrid Laguna Balamba</p><p>
Medical Enabler</p>
<p>Cel: <a href="https://api.whatsapp.com/send?phone=573157977521&text=">+57 315 797 7521</a></p>


<p>Nataly Roa Contreras</p><p>
Coordinadora de Eventos</p>
<p>Cel:  <a href="https://api.whatsapp.com/send?phone=573208500871&text=">+57 320 850 0871</a></p>

{/* <button className="btnbanner" onClick={handleClick}>WhatsApp +57 320 850 0871</button> */}


    </div>
  )
}
