import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import axios from 'axios'


export default function Login() {

    const [password, setPassword] = useState()
    const [username, setUsername] = useState()
    const [alertaRoja, setAlertaRoja] = useState(false);



    const users = [32609763,
      72292992,
      73571070,
      8722308,
      1017171188,
      1018407300,
      1128045210,
      79792964,
      52269376,
      63283005,
      5793908,
      12845781,
      80880996,
      1115072110,
      52047701,
      52084528, 1010168159,'adminroche',1032480020];   



    let navigate = useNavigate();

    const handleLogin = () => {

      for (let i = 0; i < users.length; i++) {

        if (username == users[i]) {
        
          var axios = require('axios');

          var config = {
            method: 'get',
            url: 'https://api.countapi.xyz/hit/vienna-vyuu/' + users[i],
            headers: { }
          };
          
          axios(config)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });

          var axios = require('axios');

          var config2 = {
            method: 'get',
            url: 'https://api.countapi.xyz/hit/vienna-vyuu/',
            headers: { }
          };
          
          axios(config2)
          .then(function (response) {
            
          })
          .catch(function (error) {
            console.log(error);
          });

            navigate('/app')

        }
        
      }


      setAlertaRoja(true);
     
      
      

    }

  return (
      <>  
         <div className="content">
         
             <div className="content-wrapper">
             <Collapse in={alertaRoja}>
<Alert  severity="error"
          action={
            <button className="btn close"

              onClick={() => {
                setAlertaRoja(false);
              }}
            >
              x
            </button>
          }
          sx={{ mb: 2 }}
        >
          Error: {"Contraseña incorrecta"}
        </Alert>
        </Collapse>
             <h1> Inicia sesión</h1>
             <TextField id="outlined-basic" type="text" label="Cédula"   helperText="Ingresa tu cédula"
      margin="normal"
      variant="outlined" style = {{width: 150}} onChange={(event) => {setUsername(event.target.value)}}/>
            
             
             <Button variant="contained" onClick={handleLogin}>Iniciar Sesión</Button>
               
        {/* <div className="info">
            <p>En el caso de no saber su usuario o contraseña escriba por whatsapp a </p> <p>Nataly Roa Contreras,
Coordinadora de Eventos</p>
<p>Cel:  <a href="https://api.whatsapp.com/send?phone=573208500871&text=">+57 320 850 0871</a></p>
</div> */}
</div> 

        </div>


      <div className="theborder-top">
      hola
    </div>
    <div className="theborder-left">
      hola
    </div>
    <div className="theborder-right">
      hola
    </div>
    <div className="theborder-bot">
      hola
    </div>
    <div className="logo transparent">
      hola
    </div>

    <div className="logotop transparent">
      hola
    </div>
 
    </>
  )
}
