import logo from './logo.svg';
import './App.css';
import userContext from './userContext';
import { useEffect, useState } from 'react';
import Banner from './component/Banner';
import { linkClasses } from '@mui/material';

function App() {

  const [title, setTitle] = useState();
  const [show, setShow] = useState();
  const [contenido, setContenido] = useState("")
  const [link, setLink] = useState('')
  const [image, setImage] = useState('')


const showBanner = (theTitle, theContenido, theLink, imag) => {

  if(theTitle === undefined) {
    setShow(false);
    
  }

  setTitle(theTitle);
  setContenido(theContenido);
  setLink(theLink);
  setImage(imag);
  setShow(true);
  
  

}


const showBanner1 = () => {
  showBanner("Punto De Contacto",'','https://api.whatsapp.com/send?phone=573208500871&text=');
}
const showBanner2 = () => {

  showBanner("Top 5 Restaurantes","Conozca los mejores restaurantes en Vienna, desde cenas en museos, cocina vienesa de casquería y dulces clásicos.",'https://www.wien.info/es/compras-comer-y-beber-/cocina-vienesa',"./img/top5.jpg");

}
const showBanner3 = () => {

  showBanner("Hotel",'Su reserva de hotel se encuentra en el Hotel Marriott Le Meridien, Robert-Stoltz-Platz 1, Viena  1010 Austria','https://www.espanol.marriott.com/hotels/travel/viemd-le-meridien-vienna/');

}
const showBanner4 = () => {

  showBanner("Transporte","Haga clic aquí para conocer todas las rutas del metro: horarios, precios y mapas. También podrá encontrar las opciones de transporte más cercanas a su hotel. La red de transporte público de Vienna está muy desarrollada: con metro, tranvía y autobús podrá moverse con agilidad y fiabilidad", "https://www.wien.info/es/hoteles-transporte-y-consejos-prácticos/transporte-público");

}
const showBanner5 = () => {

  showBanner("Cenas Roche", "./img/hematologia.jpeg","./img/hematoverso.jpeg");

}
const showBanner6 = () => {

  showBanner("Evento", "El congreso EHA se realizará en el Messe Wien Exhibition & Congress Center, Vienna, Austria. Encuentre aquí el programa y más información del evento.","https://ehaweb.org/congress/eha2022-hybrid/eha2022-congress/");

}
const showBanner7 = () => {

  showBanner("Hobbies", "Vienna es la capital mundial de la música. Aquí encontrará festivales de música, ópera y teatro", "https://www.wien.info/es/música-y-escenarios-en-viena");

}
const showBanner8 = () => {

  showBanner("Seguro Médico", "Usted cuenta con la Póliza Healix, encuentre más información de la cobertura y como usarla en caso de emergencia.", "./img/seguro.jpeg");

}

const showBanner9 = () => {

  showBanner("Sitios Históricos","Haga clic en este link para conocer el Top 7 de los sitios históricos de Vienna que no se puede perder.", "https://www.descubrir.com/7-sitios-historicos-para-visitar-en-viena/");

}
const showBanner10 = () => {

  showBanner("Datos Curiosos", "Vienna es una ciudad llena de historia, arte y música que esconde un montón de curiosidades que debe conocer antes de visitar. Haga clic aquí para conocer 10 curiosidades sobre Vienna.", "https://bedooin.com/austria/10-curiosidades-sobre-viena");

}

 useEffect(() => {
   
  document.getElementById('center').scrollIntoView();
    
  });

  return (
    <>
    <userContext.Provider value={{ show, setShow }}>
      <div className="content">
      
        <div className="flecha-derecha transparent">hola</div>
        <div className="flecha-izq transparent">hola</div>
        <div className="map"> 
        <div id="center">hola</div>
        <button className="btn btn1" onClick={showBanner1}> Punto de Contacto</button>
        <button className="btn btn2" onClick={showBanner2}> Top 5 restaurantes</button>
        <button className="btn btn3" onClick={showBanner3}> Hotel</button>
        <button className="btn btn4" onClick={showBanner4}> Transporte</button>
        <button className="btn btn5" onClick={showBanner5} > Cenas Roche</button>
        <button className="btn btn6" onClick={showBanner6}> Evento</button>
        <button className="btn btn7" onClick={showBanner7}> Hobbies</button>
        <button className="btn btn8" onClick={showBanner8}> Seguro Médico</button>
        <button className="btn btn9" onClick={showBanner9}> Sitios Historicos </button>
        <button className="btn btn10" onClick={showBanner10}> Datos Curiosos</button>
        {/* <img className="mapa" alt="mapa" src="./img/MAPA/MAPA COMPLETO SIN BOTONES.png"></img> */}

        {show ? <Banner title={title} show={show} link={link} contenido={contenido} img={image}/> : <p className="transparent">sdsd</p>}

        </div>
      </div>
      <div className="theborder-top">
        hola
      </div>
      <div className="theborder-left">
        hola
      </div>
      <div className="theborder-right">
        hola
      </div>
      <div className="theborder-bot">
        hola
      </div>
      <div className="logo transparent">
        hola
      </div>

      <div className="logotop transparent">
        hola
      </div>
    
  </userContext.Provider>
  </>
  );


}

export default App;
