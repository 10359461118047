import React, {useContext, useEffect } from 'react'
import userContext from '../userContext';
import PuntodeContact from './PuntodeContact';

export default function Banner(props) {

    const { show} = useContext(userContext);
    const {setShow} = useContext(userContext)


      const close = () =>{

        setShow(false);

      } 
    
    const handleClick = () => {

      // console.log(props.link)
      window.open(props.link, '_blank').focus();

    }

    const handleClick2 = () => {

      // console.log(props.link)
      window.open(props.contenido, '_blank').focus();
  

    }



    
      return (

        <>{show ?  <div className="banner">
        <button className="close" onClick={close}>X</button>
        <h1> {props.title}</h1>
        <p>{props.contenido}</p>
        <button className="btnbanner" onClick={handleClick}>Más Información</button>
        <img className="img-banner" src={props.img} alt=""/>
      </div> : <div className="banner-no"></div>}

        {props.title === "Cenas Roche" ?  <div className="banner">
          <button className="close" onClick={close}>X</button>
          <h1> {props.title}</h1>
        <p>Nos complace invitarle a participar en las siguientes cenas:</p>
<p><b>Hematología LATAM</b></p>
<p>Fecha: 9 de junio</p>
<p>Hora: 7:00pm</p>
<button className="btnbanner" onClick={handleClick2}>Más Información</button>

<p><b>Cena Restaurante Loca</b></p>
<p>Fecha: 11 de junio</p>
<p>Hora: 07:00 p.m.&nbsp;</p>

<button className="btnbanner" onClick={handleClick}>Más Información</button>

<p>Por favor Informar si tiene alguna preferencia diet&eacute;tica o restricci&oacute;n alimentaria a Nataly Roa, celular 3208500871 o al correo nataly.roa@roche.com</p>

        </div> : <div className="banner-no"></div>}
        
        {props.title === "Punto De Contacto" ?  <div className="banner">
          <button className="close" onClick={close}>X</button>
          <h1> {props.title}</h1>
          <PuntodeContact/>
        </div> :  <div className="banner-no"></div>}

        {/* <div className="banner">
          <button className="btn close" onClick={close}>X</button>
          <h1> {props.title}</h1>
          <p>{props.contenido}</p>
          <button className="btnbanner" onClick={handleClick}>Más info</button>
          <img className="img-banner" src={props.img} alt=""/>
        </div> */}

        

    </>
  )
}
